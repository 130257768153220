import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpIcon,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Disclosure, DisclosureContent, useDisclosureState } from 'reakit';
import { colors } from '../../../modules/colors';
import { PrimaryText } from '../../typography';

const AccordionStyle = createOwnUpStyle({
  margin: '32px auto',
  maxWidth: 1280
});

const Accordion = createOwnUpComponent(OwnUpBox, AccordionStyle);

const TitleStyle = createOwnUpStyle({
  alignItems: 'baseline',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  width: 'fill-available',
  variants: {
    collapsed: {
      '.decorator': {
        transition: '0.2s ease',
        transform: 'rotate(45deg)',
        '& > span > svg': {
          top: '0'
        }
      }
    },
    expanded: {
      '.decorator': {
        transition: '0.2s ease',
        transform: 'rotate(0deg)',
        '& > span > svg': {
          top: '0'
        }
      }
    }
  }
});

const Title = createOwnUpComponent(Disclosure, TitleStyle);

const QuestionStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  fontSize: 21,
  margin: '14px 24px 0',
  textAlign: 'initial',
  width: '100%',
  height: 'auto',
  textDecoration: 'none',
  backgroundImage: `linear-gradient(${colors.CLASSIC_BLUE}, ${colors.CLASSIC_BLUE})`,
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '0% 2px',
  transition: 'background-size 0.3s ease-in-out',
  ':hover': {
    color: colors.CLASSIC_BLUE,
    backgroundSize: '100% 2px'
  }
});

const Question = createOwnUpComponent(OwnUpText, QuestionStyle);

const ContentStyle = createOwnUpStyle({
  padding: 32
});

const Content = createOwnUpComponent(DisclosureContent, ContentStyle);

const AccordionPanel = ({ title, children }: PropsWithChildren<{ title: ReactNode }>) => {
  const disclosureState = useDisclosureState();
  return (
    <>
      <Title variant={disclosureState.visible ? 'expanded' : 'collapsed'} {...disclosureState}>
        <Question>{title}</Question>
        <div className="decorator">
          <OwnUpIcon
            icon="CancelX"
            color={colors.CLASSIC_BLUE}
            height="16px"
            width="16px"
            strokeWidth="5"
          />
        </div>
      </Title>
      <Content {...disclosureState}>{children}</Content>
    </>
  );
};

export const FAQAccordion = ({ title, body }: { title: string; body: JSX.Element }) => (
  <Accordion>
    <AccordionPanel title={title}>
      <PrimaryText>{body}</PrimaryText>
    </AccordionPanel>
  </Accordion>
);

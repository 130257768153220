import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React from 'react';
import { FAQs } from '../../../data/content/home/faq';
import { CallToActionButton } from '../../button';
import { HorizontalLine } from '../../horizontal-line';
import { LinkWrapper, OwnUpLink } from '../../links';
import { FAQAccordion } from '../faq-accordion';

const SectionStyle = createOwnUpStyle({
  padding: '0 32px 16px',
  variants: {
    smallAndDown: {
      padding: '0 16px 16px'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

export const FAQSection = () => (
  <Section aria-label="frequently asked questions">
    <div style={{ paddingBottom: 30 }}>
      {FAQs.map(({ question, answer }, i) => (
        <React.Fragment key={i}>
          <FAQAccordion title={question} body={answer} />
          <HorizontalLine variant="faqShort" />
        </React.Fragment>
      ))}
    </div>
    <LinkWrapper>
      <OwnUpLink
        link="/faq/"
        label="Own Up FAQ Page"
        mixpanelEvent={{
          name: 'Click FAQ CTA',
          properties: { 'page-location': 'home-more-faqs' }
        }}
      >
        <CallToActionButton>MORE FAQS</CallToActionButton>
      </OwnUpLink>
    </LinkWrapper>
  </Section>
);

import React from 'react';

export const FAQs = [
  {
    question: 'How much does the service cost me?',
    answer: (
      <span>
        The personalized advice, lender shopping, and analysis we offer is{' '}
        <u>
          <b>free</b>
        </u>
        . If you choose to get your mortgage from one of our lenders, that lender will pay us 0.40%
        of the loan amount. That’s 1/3rd of what they pay their own salespeople, and the savings are
        passed on to you in the form of a lower interest rate and lower costs. If you don’t choose
        one of our lenders &mdash; but use our tools and guidance for reassurance or to negotiate
        better terms from another lender &mdash; then we make nothing. But that’s okay, because we
        want happy customers who recommend us to their friends.
      </span>
    )
  },
  {
    question: 'Are you a lender or a bank?',
    answer: (
      <span>
        Own Up is a service designed to help you evaluate lenders, choose the right mortgage, and
        ensure you get fair terms &mdash; we do not provide financing. Instead, we use technology to
        match you with certified lenders and help you compare offers and choose the loan that best
        suits your needs. You can lock in your interest rate through Own Up and we provide ongoing
        counsel until you close your loan.
      </span>
    )
  },
  {
    question:
      'Will you sell my information to lenders and let them contact me without my permission?',
    answer: (
      <span>
        No. Your Home Advisor will help you evaluate multiple choices among our certified lenders.
        Once you’ve found the best option for your unique situation, only then will we introduce you
        to your chosen lender. You will not receive email, phone calls, or texts from any of our
        lenders until you opt-in. After introducing you to your lender your advisor will be with you
        throughout the way, monitoring your loan to make sure you get the same high level of service
        from our lender that you receive from Own Up.
      </span>
    )
  },
  {
    question: 'Will working with Own Up affect my credit?',
    answer: (
      <span>
        No. To determine the interest rates and terms you qualify for, Own Up utilizes a soft credit
        inquiry that does NOT affect your credit score. We do NOT require you to provide your Social
        Security Number (SSN) to use our service.
      </span>
    )
  }
];
